<template>
  <v-container fluid>
    <v-card elevation="0" class="px-3 mb-5">
      <v-card-title class="text-h4 grey--text text--darken-3">
        Create Meeting
      </v-card-title>
    </v-card>

    <v-card elevation="0" :max-height="viewPortHeight / 1.4" class="px-3 mt-3">
      <v-row class="fill-height">
        <v-col cols="8">
          <v-form
            ref="createMeetingForm"
            lazy-validation
            class="px-6 card-border"
          >
            <v-text-field
              v-model="meeting.meetingTitle"
              placeholder="Meeting Title"
              class="text-h6"
              :rules="rules.titleRule"
            >
            </v-text-field>
            <v-card
              elevation="0"
              :max-height="viewPortHeight / 1.7"
              class="overflow-y-auto overflow-x-hidden pa-0 ma-0"
            >
              <v-row no-gutters>
                <v-col class="pr-2">
                  <v-menu
                    v-model="startDateCalPicker"
                    :close-on-content-click="false"
                    max-width="290"
                    :rules="rules.dateRule"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="startDateFormatter"
                        readonly
                        outlined
                        dense
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="meeting.startDate = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="meeting.startDate"
                      @change="startDateCalPicker = false"
                      :min="minDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col v-if="!meeting.allDay">
                  <v-select
                    hide-details
                    :items="timeItems"
                    v-model="meeting.startTime"
                    label="Start Time"
                    dense
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="1" align="center" class="mt-2"> to </v-col>
                <v-col v-if="!meeting.allDay">
                  <v-select
                    :items="timeItems"
                    hide-details
                    label="End Time"
                    v-model="meeting.endTime"
                    dense
                    outlined
                  ></v-select>
                </v-col>
                <v-col class="pl-2">
                  <v-menu
                    v-model="endDateCalPicker"
                    :close-on-content-click="false"
                    max-width="290"
                    :rules="rules.dateRule"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="endDateFormatter"
                        readonly
                        hide-details
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="endDate = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="meeting.endDate"
                      @change="endDateCalPicker = false"
                      :min="minDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-checkbox
                dense
                v-model="meeting.allDay"
                label="All Day"
                hide-details
              ></v-checkbox>

              <v-divider></v-divider>

              <v-checkbox
                dense
                v-model="meeting.addInterpretation"
                label="Add Interpretation?"
                prepend-icon="mdi-translate"
                hide-details
              ></v-checkbox>
              <div class="ma-5" v-if="meeting.addInterpretation">
                <v-row align="center">
                  <v-col cols="3">
                    <v-subheader> Default Language: </v-subheader>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      v-model="meeting.defaultLanguage"
                      :disabled="!changeDefaultLanguage"
                      dense
                      outlined
                      hide-details
                      item-text="label"
                      item-value="code"
                      :items="languageList"
                    >
                    </v-select>
                  </v-col>
                  <v-col>
                    <v-checkbox
                      dense
                      class="my-auto"
                      v-model="changeDefaultLanguage"
                      label="Change default language"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="3">
                    <v-subheader> Target Language: </v-subheader>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      v-model="meeting.targetLanguage"
                      dense
                      label="Select language"
                      outlined
                      hide-details
                      item-text="label"
                      item-value="code"
                      :items="languageList"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </div>
              <v-checkbox
                dense
                v-model="meeting.addCountdownTimer"
                label="Countdown Timer"
                prepend-icon="mdi-timer-outline"
                hide-details
              ></v-checkbox>
              <div class="ma-5" v-if="meeting.addCountdownTimer">
                <v-row
                  v-for="(item, i) in meeting.countdown"
                  :key="i"
                  no-gutters
                  align="center"
                  justify="start"
                >
                  <v-col cols="2">
                    <v-text-field
                      v-model="meeting.countdown[i].value"
                      placeholder="E.g. 10"
                      outlined
                      dense
                      :rules="rules.counterTimerRule"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-select
                      :items="['Hours', 'Minutes']"
                      v-model="meeting.countdown[i].type"
                      class="ml-2"
                      dense
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col class="ml-2">
                    <v-btn
                      @click="meeting.countdown.splice(i, 1)"
                      class="ml-5 mb-6"
                      icon
                      color="error"
                      small
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-btn
                  text
                  outlined
                  small
                  color="primary"
                  @click="
                    meeting.countdown.push({ value: '', type: 'Minutes' })
                  "
                >
                  <v-icon left>mdi-plus</v-icon> Add Timers</v-btn
                >
                <v-divider></v-divider>
              </div>
              <v-row>
                <v-col>
                  <v-checkbox
                    dense
                    v-model="meeting.textMessage"
                    prepend-icon="mdi-message-text"
                    label="Text message"
                    hide-details
                  >
                  </v-checkbox>
                </v-col>

                <v-col>
                  <v-checkbox
                    dense
                    v-model="meeting.screenSharing"
                    label="Screen sharing"
                    prepend-icon="mdi-share-all-outline"
                    hide-details
                  ></v-checkbox>
                </v-col>

                <v-col>
                  <v-checkbox
                    dense
                    v-model="meeting.handRaise"
                    label="Hand Raise"
                    prepend-icon="mdi-hand-front-left-outline"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-textarea
                class="mt-3"
                filled
                v-model="meeting.description"
                placeholder="Meeting description"
                value=""
              ></v-textarea>
            </v-card>
          </v-form>
        </v-col>
        <v-col align="center" cols="4">
          <v-row no-gutters>
            <v-col cols="12" class="pa-2">
              <v-btn elevation="0" color="primary" @click="createMeeting">
                Save Meeting
              </v-btn>
              <v-divider class="mt-1"></v-divider>
            </v-col>
            <v-col>
              <div class="my-2">
                <v-card
                  elevation="0"
                  max-width="400"
                  class="pt-1 px-3"
                  align="left"
                >
                  <!-- <participantList></participantList> -->
                  <pl></pl>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment';
import { format, parseISO, isBefore, isEqual } from "date-fns";
import { mapGetters } from "vuex";
// import participantList from "./participantList.vue";
import pl from './plTest.vue'


export default {
  name: "createMeeting",
  components: {
    // participantList,
    pl
  },
  data() {
    return {
      meeting: {
        meetingTitle: "",
        startDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
        endDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
        startTime: "",
        endTime: "",
        allDay: false,
        addInterpretation: false,
        addCountdownTimer: false,
        defaultLanguage: "en",
        targetLanguage: "",
        countdown: [{ value: "", type: "Minutes" }],
        description: null,
        textMessage: false,
        handRaise: false,
        screenSharing: false,
      },
      minDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      startDateCalPicker: false,
      endDateCalPicker: false,
      changeDefaultLanguage: false,
      languageList: [
        { label: "English", code: "en" },
        { label: "Spanish", code: "es" },
        { label: "Vietnamese", code: "vi" },
        { label: "Navajo", code: "nv" },
      ],
      timeItems: [
        "12:00 AM",
        "12:15 AM",
        "12:30 AM",
        "12:45 AM",
        "1:00 AM",
        "1:15 AM",
        "1:30 AM",
        "1:45 AM",
        "2:00 AM",
        "2:15 AM",
        "2:30 AM",
        "2:45 AM",
        "3:00 AM",
        "3:15 AM",
        "3:30 AM",
        "3:45 AM",
        "4:00 AM",
        "4:15 AM",
        "4:30 AM",
        "4:45 AM",
        "5:00 AM",
        "5:15 AM",
        "5:30 AM",
        "5:45 AM",
        "6:00 AM",
        "6:15 AM",
        "6:30 AM",
        "6:45 AM",
        "7:00 AM",
        "7:15 AM",
        "7:30 AM",
        "7:45 AM",
        "8:00 AM",
        "8:15 AM",
        "8:30 AM",
        "8:45 AM",
        "9:00 AM",
        "9:15 AM",
        "9:30 AM",
        "9:45 AM",
        "10:00 AM",
        "10:15 AM",
        "10:30 AM",
        "10:45 AM",
        "11:00 AM",
        "11:15 AM",
        "11:30 AM",
        "11:45 AM",
        "12:00 PM",
        "12:15 PM",
        "12:30 PM",
        "12:45 PM",
        "1:00 PM",
        "1:15 PM",
        "1:30 PM",
        "1:45 PM",
        "2:00 PM",
        "2:15 PM",
        "2:30 PM",
        "2:45 PM",
        "3:00 PM",
        "3:15 PM",
        "3:30 PM",
        "3:45 PM",
        "4:00 PM",
        "4:15 PM",
        "4:30 PM",
        "4:45 PM",
        "5:00 PM",
        "5:15 PM",
        "5:30 PM",
        "5:45 PM",
        "6:00 PM",
        "6:15 PM",
        "6:30 PM",
        "6:45 PM",
        "7:00 PM",
        "7:15 PM",
        "7:30 PM",
        "7:45 PM",
        "8:00 PM",
        "8:15 PM",
        "8:30 PM",
        "8:45 PM",
        "9:00 PM",
        "9:15 PM",
        "9:30 PM",
        "9:45 PM",
        "10:00 PM",
        "10:15 PM",
        "10:30 PM",
        "10:45 PM",
        "11:00 PM",
        "11:15 PM",
        "11:30 PM",
        "11:45 PM",
      ],
      rules: {
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        titleRule: [(v) => !!v || "Meeting title cannot be left empty."],
        counterTimerRule: [
          (v) => !!v || "Counter cannot be left emapty.",
          (v) => /^\d+$/.test(v) || "Only digits are allowed.",
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["userProfile"]),

    startDateFormatter() {
      return this.meeting.startDate
        ? format(parseISO(this.meeting.startDate), "MMM d, yyyy")
        : "";
    },
    endDateFormatter() {
      return this.meeting.endDate
        ? format(parseISO(this.meeting.endDate), "MMM d, yyyy")
        : "";
    },
    viewPortHeight() {
      return window.innerHeight;
    },
  },
  methods: {
    createMeeting() {
      if (this.$refs.createMeetingForm.validate()) {
        // console.log('Check Dates: ', this.checkDates() );
        // console.log('Check Inter: ', this.checkInterpretation());
        // console.log('Check Time: ', this.checkTimeDifference());
        if (this.checkDates && this.checkInterpretation && this.checkTimeDifference) {
          this.$store.dispatch("createMeeting", this.meeting).then(
            (response) => {
              this.$store.commit("setLocalParticipantsList", []);
              this.clearForm();
              this.$store.dispatch("showSnackBar", {
                text: response,
                color: "success",
              });
            },
            (error) => {
              this.$store.dispatch("showSnackBar", {
                text: error,
                color: "error",
              });
            }
          );
        } 
      }
    },
    checkDates() {      
      if (
        isEqual(
          parseISO(this.meeting.startDate),
          parseISO(this.meeting.endDate)
        ) ||
        isBefore(
          parseISO(this.meeting.startDate),
          parseISO(this.meeting.endDate)
        )
      ) {
        return true;
      } else {
        this.$store.dispatch("showSnackBar", {
          text: "End date cannot be smaller than Start Date",
          color: "error",
        });
        return false;
      }
    },
    checkInterpretation() {
      if (this.meeting.addInterpretation) {
        if (this.meeting.targetLanguage !== "") {
          return true;
        } else {
          this.$store.dispatch("showSnackBar", {
            text: "Please select the target interpretation language",
            color: "error",
          });
          return false;
        }
      } else {
        this.meeting.targetLanguage = "";
        this.meeting.defaultLanguage = "en";
        return true;
      }
    },
    checkTimeDifference() {
      if (this.meeting.allDay) {
        this.meeting.startTime = "";
        this.meeting.endTime = "";
        return true;
      } else {
        if (this.meeting.startTime === "") {
          this.$store.dispatch("showSnackBar", {
            text: "Please select the start time.",
            color: "error",
          });
          return false;
        }
        if (this.meeting.endTime === "") {
          this.$store.dispatch("showSnackBar", {
            text: "Please select the end time.",
            color: "error",
          });
          return false;
        }        
        let startTime = moment(this.meeting.startTime, "hh:mm A")
        let endTime = moment(this.meeting.endTime, "hh:mm A")        
        if(endTime.diff(startTime) < 0){
          this.$store.dispatch("showSnackBar", {
            text: "Start time cannot be past end time",
            color: "error",
          });
          return false;
        }
        return true

      }
    },
    clearForm() {
      this.$refs.createMeetingForm.reset();
      this.meeting = {        
        startDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
        endDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),        
        defaultLanguage: "en",        
        countdown: [{ value: "", type: "Minutes" }],        
      };
    },
  },
};
</script>

<style>
.card-border {
  border-right: 1px solid #bcbcbc !important;
}
</style>


