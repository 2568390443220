<template>
  <div>
    <v-autocomplete
      outlined
      :search-input.sync="search"
      :items="userList"
      hide-details=""
      dense
      label="Add Participants"      
      item-text="name"
      item-value="email"
      v-model="selectedItems"
      @change="clearText()"
      :append-outer-icon="isWildcard ? 'mdi-account-plus' : ''"
      @click:append-outer="addWildcardParticipant"
      :no-data-text="noDataText"
      clearable
      >
      <template v-slot:item="data">
        <v-list-item-avatar>
          <v-avatar color="primary" size="36" class="white--text">
            {{ getUserInitials(data.item.name) }}
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content @click="addParticipant(data.item, 'Participant')">
          <v-list-item-title v-html="data.item.name"></v-list-item-title>
          <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>

    <v-virtual-scroll
      :items="getLocalParticipantsList"
      bench="0"
      height="350"
      max-height="500"
      item-height="64"
    >
      <template v-slot:default="{ item }">
        <v-list-item two-line v-if="!item.isWildcard">
          <v-list-item-avatar>
            <v-avatar color="primary" size="36" class="white--text">
              <div class="">
                {{ getUserInitials(item.name) }}
              </div>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="subtitle-2">
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle class="caption">
              {{ item.meetingRole }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-btn
            class="mr-1"
            :color="
              item.meetingRole === 'Moderator' ? 'amber darken-1' : 'grey'
            "
            :outlined="item.meetingRole === 'Moderator' ? false : true"
            elevation="0"
            fab
            width="20"
            height="20"
            dark
            @click="changeUserRole(item.id, 'Moderator')"
          >
            <v-icon size="15"> mdi-crown-outline </v-icon>
          </v-btn>
          <v-btn
            :color="
              item.meetingRole === 'Interpreter' ? 'primary darken-1' : 'grey'
            "
            :outlined="item.meetingRole === 'Interpreter' ? false : true"
            elevation="0"
            fab
            width="20"
            height="20"
            dark
            @click="changeUserRole(item.id, 'Interpreter')"
          >
            <v-icon size="15"> mdi-translate </v-icon>
          </v-btn>
          <v-btn
            class="ml-1"
            :color="
              item.meetingRole === 'Participant' ? 'primary darken-1' : 'grey'
            "
            :outlined="item.meetingRole === 'Participant' ? false : true"
            elevation="0"
            fab
            width="20"
            height="20"
            dark
            @click="changeUserRole(item.id, 'Participant')"
          >
            <v-icon size="15"> mdi-account </v-icon>
          </v-btn>
          <v-btn
            class="ml-2"
            color="error"
            elevation="0"
            icon
            width="20"
            height="20"
            dark
            @click="removeParticipant(item.id)"
          >
            <v-icon size="15"> mdi-trash-can-outline </v-icon>
          </v-btn>
        </v-list-item>

        <v-list-item two-line v-else>
          <v-list-item-avatar>
            <v-avatar color="" size="36" class="white--text">
              <div class="">
                <v-icon x-large>mdi-account-circle</v-icon>
              </div>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="subtitle-2">
              {{ item.email }}
            </v-list-item-title>
            <v-list-item-subtitle class="caption">
              {{ item.meetingRole }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-btn
            :color="item.meetingRole === 'Interpreter' ? 'primary darken-1' : 'grey'"
            :outlined="item.meetingRole === 'Interpreter' ? false : true"
            elevation="0"
            fab
            width="20"
            height="20"
            dark
            @click="changeUserRole(item.id, 'Interpreter')">
            <v-icon size="15"> mdi-translate </v-icon>
          </v-btn>
          <v-btn
            class="ml-1"
            :color="item.meetingRole === 'Participant' ? 'primary darken-1' : 'grey'"
            :outlined="item.meetingRole === 'Participant' ? false : true"
            elevation="0"
            fab
            width="20"
            height="20"
            dark
            @click="changeUserRole(item.id, 'Participant')">
            <v-icon size="15"> mdi-account </v-icon>
          </v-btn>
          <v-btn
            class="ml-2"
            color="error"
            elevation="0"
            icon
            width="20"
            height="20"
            dark
            @click="removeParticipant(item.id)">
            <v-icon size="15"> mdi-trash-can-outline </v-icon>
          </v-btn>
        </v-list-item>
      </template>
    </v-virtual-scroll>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import crypto from "crypto";

export default {
  name: "plTest",
  data() {
    return {      
      isWildcard: false,
      participantsMap: new Map(),
      queryTerm: null,
      selectedItems: [],
      noDataText: "Enter email address",
    };
  },
  computed: {
    ...mapGetters([
      "allRegisteredUsers",
      "userProfile",
      "getLocalParticipantsList",
    ]),
    userList() {
      let list = [];
      this.allRegisteredUsers.forEach((element) => {
        const data = {
          id: element.id,
          email: element.data().email,
          name: element.data().name,
          meetingRole: "",
          isWildcard: false,
        };
        list.push(data);
      });

      // console.log(
      //   list.filter(
      //     (x) =>
      //       this.getLocalParticipantsList.filter((e) => e.id === x.id)
      //         .length === 0
      //   ).length
      // );

      return list.filter(
        (x) =>
          this.getLocalParticipantsList.filter((e) => e.id === x.id).length ===
          0
      );
    },
    search: {
      get() {
        return this.queryTerm;
      },
      set(input) {
        this.isWildcard = /.+@.+\..+/.test(input);
        this.queryTerm = input;
        // console.log(this.isWildcard);
        if (this.isWildcard) {
          this.noDataText = "Click on + button, to add participant";
        } else {
          this.noDataText = "Enter participant's email address";
        }
      },
    },
  },
  watch: {
    getLocalParticipantsList(n, o) {      
      if (n.length === 0) {
        this.participantsMap.clear();        
        this.addMySelf();
      }
    },
  },
  methods: {
    clearText() {
      this.selectedItems = [];
    },
    addParticipant(user, role) {
      user.meetingRole = role;
      this.participantsMap.set(user.id, user);
      this.$store.commit(
        "setLocalParticipantsList",
        Array.from(this.participantsMap.values())
      );
    },
    getUserInitials(name) {
      let initials = "";
      if (name !== null || name !== "") {
        let split = name.split(" ");
        split.forEach((item) => {
          initials += item[0];
        });
        initials = initials.substring(0, 2);
      }
      return initials;
    },
    changeUserRole(participantId, role) {
      let pData = this.participantsMap.get(participantId);
      pData.meetingRole = role;
      this.participantsMap.set(participantId, pData);
      this.$store.commit(
        "setLocalParticipantsList",
        Array.from(this.participantsMap.values())
      );
    },
    removeParticipant(participantId) {
      this.participantsMap.delete(participantId);
      this.$store.commit(
        "setLocalParticipantsList",
        Array.from(this.participantsMap.values())
      );
    },
    generateUserKey() {
      const id = crypto.randomBytes(5).toString("hex");
      if (this.participantsMap.has(id)) {
        this.generateUserKey();
      } else {
        return id;
      }
    },
    addWildcardParticipant(){      
      const data = {
          id: this.generateUserKey(),
          email: this.search,
          name: '',
          meetingRole: "Participant",
          isWildcard: this.isWildcard,
        };
        this.addParticipant(data, "Participant")
    },
    fetchRegisteredUsers() {
      if (this.allRegisteredUsers.length === 0) {
        this.$store.dispatch("getAllRegisteredUsers").then(
          () => {
            this.loading = false;
          },
          (error) => {
            this.$store.dispatch("showSnackBar", {
              text: error,
              color: "error",
              timeout: 0,
            });
          }
        );
      }
    },
    addMySelf() {
      let user = {
        id: this.userProfile.id,
        email: this.userProfile.data().email,
        name: this.userProfile.data().name,
        meetingRole: "",
        isWildcard: false,
      };
      this.addParticipant(user, "Moderator");
    },
  },
  mounted() {
    this.fetchRegisteredUsers();
    this.addMySelf();
  },
};
</script>